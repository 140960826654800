/*
  The .ui.menu is needed otherwise there's a more specific rule from semantic UI
  we use this rule to tweak Semantic UI's CSS
*/
@import "../../styles/shared.scss";

.ui.menu.top-menu {
  border: none;
  .logo {
    width: 17rem;
    font-size: 1rem;
    color: #10a3a3;
  }

  .nav-container {
    display: flex;
    // justify-content: space-between;
    flex-grow: 1;
    padding: 0;
    font-size: 1.2rem;

    // .search-input {
    //   padding-left: 0;
    //   width: 33%;

    //   form {
    //     width: 100%;
    //   }
    // }
  }

  .nav-container-links {
    display: flex;
    /* align-items: stretch; by default */
    font-size: 1.2rem;
  }

  .nav-container-right {
    font-size: 1.2rem;
  }

  .header-icon {
    color: #17d7d7;
  }

  // @media screen and (max-width: 1127px) {
  //   .nav-container {
  //     flex-direction: column; /* change the direction of the container */
  //     font-size: 1.2rem;
  //   }

  //   .nav-container-links {
  //     flex-direction: column;
  //     font-size: 1.2rem;
  //   }
  // }
}
