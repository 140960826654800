.sidebar-item {
  background: #f5f5f5;
  span {
    i.icon {
      margin-right: 20px;
      color: #888888;
    }
  }

  &.highlight-item {
    span {
      font-weight: 600;
    }

    i.icon {
      color: #05a3a3;
    }
  }
}

.sidebar-item-alignment-container {
  display: flex;
  align-items: center;
}
