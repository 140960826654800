$grey: #888888;
$red: #ff0002;
$header-nav-height: 64px;
$sidebar-left-width: 16rem;
$text-color-dark: #111111;
$avatar-diameter: 48px;
$avatar-margin: 10px;

.genre-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1400px;
  grid-gap: 15px;
  justify-items: center;
  margin: auto;
  h3 {
    margin: 200px auto;
  }
}

.breakpoint__medium-only {
  .genre-list {
    grid-template-columns: repeat(3, 1fr);
  }
  h3 {
    font-size: 18px;
    width: 80%;
  }
  .genre-component {
    width: 170px;
    height: 170px;
    h3 {
      font-size: 22px;
    }
  }
}

.breakpoint__small-down {
  .genre-list {
    grid-template-columns: repeat(2, 1fr);
  }
  h3 {
    font-size: 18px;
    width: 80%;
  }
  .genre-component {
    width: 140px;
    height: 140px;
    font-size: 18px;
  }
}
