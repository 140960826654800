@import "../../../styles/shared.scss";

$sidebar-hover-color: #ebebeb;

.ui.menu.fixed.side-nav {
  background-color: #f5f5f5;
  margin-top: $header-nav-height;
  padding-bottom: $header-nav-height;
  overflow-y: auto;
}

.side-nav.ui.vertical.menu {
  width: $sidebar-left-width;
}
