@import "../../../styles/shared.scss";

.parks-content {
  margin-left: $sidebar-left-width;
  padding-left: 5px;
  padding-right: 5px;
  display: grid;
  grid: auto / auto;
  // justify-content: center;
}
@media all and (min-width: 476px) {
  .responsive-video-grid-container {
    max-width: 240px;
  }
}

@media all and (min-width: 700px) {
  .responsive-video-grid-container {
    max-width: 472px;
  }
}
