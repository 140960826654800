@import "../../../styles/shared.scss";

.finance-content {
  margin-left: $sidebar-left-width;
  padding-left: 5px;
  padding-right: 5px;
  display: grid;
  grid: auto / auto;
  // justify-content: center; Commented out cuz it squeezes table to center
}
// @media all and (min-width: 476px) {
//   .responsive-video-grid-container {
//     max-width: 240px;
//   }
// }

// @media all and (min-width: 700px) {
//   .responsive-video-grid-container {
//     max-width: 472px;
//   }
// }
