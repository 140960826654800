.ballot-container {
  margin-top: 40px;
  margin-left: 35px;
  margin-right: 35px;
  margin-bottom: 40px;
  box-sizing: border-box;
  // border: 0.5px dotted teal;
  // outline: 1px solid black;
  // outline-offset: -2px;
}

.line1 {
  position: absolute;
  top: 635px;
  left: 400px;
  width: 100%;
}
.line2 {
  position: absolute;
  top: 700px;
  left: 400px;
  width: 100%;
}
.line3 {
  position: absolute;
  top: 765px;
  left: 400px;
  width: 100%;
}
.line4 {
  position: absolute;
  top: 830px;
  left: 400px;
  width: 100%;
}
.line5 {
  position: absolute;
  top: 895px;
  left: 400px;
  width: 100%;
}
.line6 {
  position: absolute;
  top: 960px;
  left: 400px;
  width: 100%;
}
.date1 {
  position: absolute;
  top: 384px;
  left: 395px;
}
.date2 {
  position: absolute;
  top: 1132px;
  left: 440px;
}
.date3 {
  position: absolute;
  top: 1582px;
  left: 390px;
}

h2 span {
  color: black;
  font: bold 24px/45px Helvetica, Sans-Serif;
  // letter-spacing: -1px;
  // background: rgb(0, 0, 0); /* fallback color */
  // background: rgba(0, 0, 0, 0.7);
  padding: 10px;
}

h2 span.spacer {
  padding: 0 5px;
}

@media print {
  html,
  body {
    height: 100vh;
    margin-left: 120px;
    margin-top: 105px;
    padding: 0;
    overflow: hidden;
  }
  .line1 {
    position: absolute;
    top: 555px;
    left: 430px;
    width: 100%;
  }
  .line2 {
    position: absolute;
    top: 620px;
    left: 430px;
    width: 100%;
  }
  .line3 {
    position: absolute;
    top: 685px;
    left: 430px;
    width: 100%;
  }
  .line4 {
    position: absolute;
    top: 750px;
    left: 430px;
    width: 100%;
  }
  .line5 {
    position: absolute;
    top: 815px;
    left: 430px;
    width: 100%;
  }
  .line6 {
    position: absolute;
    top: 880px;
    left: 430px;
    width: 100%;
  }
  .date1 {
    position: absolute;
    top: 305px;
    left: 408px;
  }
  .date2 {
    position: absolute;
    top: 1069px;
    left: 413px;
  }
  .date3 {
    position: absolute;
    top: 1503px;
    left: 398px;
  }
}
